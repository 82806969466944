<script>
import appConfig from "@/app.config";

/**
 * Maintenance component
 */
export default {
  page: {
    title: "Maintenance",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  computed : {
    textMaintenance() {
      if(this.$route.query.hasOwnProperty("message")){
        return this.$route.query.message;
      } else {
        return 'Please check back in sometime.'
      }
    }
  }
};
</script>
<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <!-- <router-link tag="a" to="/" class="text-dark">
        <i class="fas fa-home h2"></i>
      </router-link> -->
    </div>
    <section class="my-5 pt-sm-5">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <div class="home-wrapper" style="padding-left: 20px!important; padding-right: 20px!important;">
              <!-- <div class="mb-5">
                <div class="d-block auth-logo" style="display: flex !important; align-items: center; justify-content: center;">
                  <img
                    src="@/assets/images/brands/logo_ss.png"
                    alt=""
                    height="60"
                    class="auth-logo-dark mb-3"
                  />
                  <img
                    src="@/assets/images/brands/logo_ss.png"
                    alt=""
                    height="60"
                    class="auth-logo-light mb-3"
                  />
                  <h3 style="margin-left: 1rem;">Software Sekolah</h3>
                </div>
              </div> -->

              <div class="row justify-content-center">
                <div class="col-sm-4">
                  <div class="maintenance-img">
                    <img
                      src="@/assets/images/maintenance.svg"
                      alt
                      class="img-fluid mx-auto d-block"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-5 text-center" v-if="$route.query.appRoute">
                <router-link class="btn btn-primary" :to="{name : 'payment', query : {appRoute : this.$route.query.appRoute}}">Kembali ke Daftar Tagihan</router-link>
              </div>
              <h3 class="mt-5 mb-3">Payment Under Maintenance</h3>
              <p>{{textMaintenance}}</p>

              <!-- <div class="row">
                <div class="col-md-6">
                  <div class="card mt-4 maintenance-box">
                    <div class="card-body">
                      <i class="bx bx-broadcast mb-4 h1 text-primary"></i>
                      <h5 class="font-size-15 text-uppercase">
                        Why is the Site Down?
                      </h5>
                      <p class="text-muted mb-0">
                        There are many variations of passages of Lorem Ipsum
                        available, but the majority have suffered alteration.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card mt-4 maintenance-box">
                    <div class="card-body">
                      <i class="bx bx-time-five mb-4 h1 text-primary"></i>
                      <h5 class="font-size-15 text-uppercase">
                        What is the Downtime?
                      </h5>
                      <p class="text-muted mb-0">
                        Contrary to popular belief, Lorem Ipsum is not simply
                        random text. It has roots in a piece of classical.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card mt-4 maintenance-box">
                    <div class="card-body">
                      <i class="bx bx-envelope mb-4 h1 text-primary"></i>
                      <h5 class="font-size-15 text-uppercase">
                        Do you need Support?
                      </h5>
                      <p class="text-muted mb-0">
                        If you are going to use a passage of Lorem Ipsum, you
                        need to be sure there isn't anything embar..
                        <a
                          href="mailto:no-reply@domain.com"
                          class="text-decoration-underline"
                          >@softwaresekolah.com</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- end row -->
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
